<template>
  <svg
    id="svg3"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg">
    <defs id="defs7" />
    <circle id="Ellipse 345" opacity="0.8" cx="15" cy="15" r="15" fill="#5733e4" />
    <path
      id="XMLID_712_"
      d="m 11.772838,22.508474 c -0.33241,0 -0.63712,-0.1076 -0.88643,-0.3497 l -5.5124591,-5.35251 c -0.498615,-0.48415 -0.498615,-1.23728 0,-1.72142 0.498615,-0.48415 1.274249,-0.48415 1.772859,0 l 4.6537301,4.49183 11.05265,-10.7050875 c 0.4986,-0.484149 1.2742,-0.484149 1.7728,0 0.4987,0.48415 0.4987,1.2372775 0,1.7214275 l -11.93902,11.56576 c -0.277,0.2421 -0.58172,0.3497 -0.91413,0.3497 z"
      fill="#ffffff" />
  </svg>
</template>
