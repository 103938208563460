<template>
  <svg width="51" height="90" viewBox="0 0 51 90" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M32.087 74.3478C32.087 76.0109 30.8152 77.2826 29.1522 77.2826H21.6196C19.9565 77.2826 18.6848 76.0109 18.6848 74.3478C18.6848 72.6848 19.9565 71.413 21.6196 71.413H29.1522C30.8152 71.413 32.087 72.6848 32.087 74.3478ZM50.8696 7.43478V82.5652C50.8696 86.6739 47.5435 90 43.4348 90H7.43478C3.32609 90 0 86.6739 0 82.5652V7.43478C0 3.32609 3.32609 0 7.43478 0H43.4348C47.5435 0 50.8696 3.32609 50.8696 7.43478ZM7.82609 7.82609V59.6739H43.0435V7.82609H7.82609ZM43.0435 82.1739V65.5435H7.82609V82.1739H43.0435Z"
      fill="url(#paint0_linear_407_6115)" />
    <defs>
      <linearGradient
        id="paint0_linear_407_6115"
        x1="7.74102"
        y1="13.2065"
        x2="61.4573"
        y2="46.5542"
        gradientUnits="userSpaceOnUse">
        <stop offset="0.245" stop-color="#1EEAAD" />
        <stop offset="1" stop-color="#5733E4" />
      </linearGradient>
    </defs>
  </svg>
</template>
