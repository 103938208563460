import { createGtm } from '@gtm-support/vue-gtm'

export default defineNuxtPlugin(nuxtApp => {
  const runtimeConfig = useRuntimeConfig()
  const clickskeksConsent = import.meta.client ? localStorage.getItem('ccm_consent') : '{}'
  const clickskeksConsentValue = JSON.parse(clickskeksConsent || '{}')
  nuxtApp.vueApp.use(
    createGtm({
      id: runtimeConfig.public.gtmId,
      defer: false,
      compatibility: false,
      enabled: clickskeksConsentValue['/']?.embeddings.includes(
        runtimeConfig.public.gtmCookieEmbedCode,
      ),
      debug: runtimeConfig.public.sentry.environment !== 'production',
      vueRouter: useRouter(),
      trackOnNextTick: false,
    }),
  )
})
