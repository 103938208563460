<template>
  <svg width="62" height="29" viewBox="0 0 62 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="61" height="28" rx="2.5" stroke="#BACBDD" />
    <path
      d="M14.28 9.00031H10.8577C10.6234 9.00031 10.4243 9.1705 10.3878 9.40176L9.00359 18.1776C8.97606 18.3507 9.11021 18.5068 9.28583 18.5068H10.9197C11.1539 18.5068 11.3531 18.3367 11.3896 18.105L11.7629 15.7381C11.799 15.5063 11.9986 15.3362 12.2323 15.3362H13.3157C15.5701 15.3362 16.8712 14.2453 17.211 12.0834C17.3641 11.1376 17.2175 10.3945 16.7746 9.87411C16.2882 9.30258 15.4255 9.00031 14.28 9.00031ZM14.6749 12.2056C14.4877 13.4336 13.5494 13.4336 12.6421 13.4336H12.1257L12.488 11.1402C12.5096 11.0015 12.6296 10.8995 12.7698 10.8995H13.0064C13.6244 10.8995 14.2075 10.8995 14.5087 11.2517C14.6884 11.4619 14.7434 11.7742 14.6749 12.2056Z"
      fill="black" />
    <path
      d="M24.5092 12.1663H22.8704C22.7308 12.1663 22.6102 12.2684 22.5886 12.407L22.5162 12.8654L22.4015 12.6992C22.0467 12.1843 21.2555 12.0122 20.4659 12.0122C18.6549 12.0122 17.1081 13.3838 16.8068 15.3079C16.6502 16.2677 16.8729 17.1855 17.4173 17.8255C17.9168 18.414 18.6313 18.6592 19.4816 18.6592C20.9408 18.6592 21.75 17.721 21.75 17.721L21.6769 18.1763C21.6494 18.3505 21.7835 18.5066 21.9582 18.5066H23.4343C23.6691 18.5066 23.8672 18.3364 23.9043 18.1048L24.79 12.4956C24.8181 12.3229 24.6844 12.1663 24.5092 12.1663ZM22.2249 15.356C22.0668 16.2922 21.3236 16.9208 20.3759 16.9208C19.9 16.9208 19.5196 16.7681 19.2754 16.4789C19.0332 16.1916 18.9411 15.7828 19.0182 15.3274C19.1658 14.3991 19.9215 13.7501 20.8547 13.7501C21.3202 13.7501 21.6984 13.9047 21.9477 14.1964C22.1974 14.4912 22.2964 14.9026 22.2249 15.356Z"
      fill="black" />
    <path
      d="M33.2392 12.1659L31.5923 12.1659C31.4352 12.1659 31.2875 12.2439 31.1985 12.3746L28.9271 15.7204L27.9643 12.5052C27.9037 12.304 27.7181 12.1659 27.5079 12.1659H25.8896C25.6929 12.1659 25.5563 12.358 25.6188 12.5432L27.4328 17.8666L25.7274 20.2742C25.5933 20.4638 25.7284 20.7246 25.9602 20.7246H27.605C27.7611 20.7246 27.9073 20.6485 27.9958 20.5204L33.4734 12.6138C33.6045 12.4246 33.4699 12.1659 33.2392 12.1659Z"
      fill="black" />
    <path
      d="M38.6902 9.00031H35.2673C35.0336 9.00031 34.8344 9.1705 34.7979 9.40176L33.4137 18.1776C33.3863 18.3507 33.5203 18.5068 33.695 18.5068H35.4515C35.6146 18.5068 35.7543 18.3878 35.7798 18.2256L36.1725 15.7381C36.2086 15.5063 36.4083 15.3362 36.642 15.3362H37.7249C39.9797 15.3362 41.2804 14.2453 41.6206 12.0834C41.7743 11.1376 41.6267 10.3945 41.1838 9.87411C40.6979 9.30258 39.8357 9.00031 38.6902 9.00031ZM39.085 12.2056C38.8984 13.4336 37.9601 13.4336 37.0523 13.4336H36.5364L36.8992 11.1402C36.9207 11.0015 37.0398 10.8995 37.1804 10.8995H37.4171C38.0347 10.8995 38.6181 10.8995 38.9194 11.2517C39.099 11.4619 39.1535 11.7742 39.085 12.2056Z"
      fill="black" />
    <path
      d="M48.9213 12.1663H47.2834C47.1427 12.1663 47.0231 12.2684 47.0021 12.407L46.9295 12.8654L46.8145 12.6992C46.4597 12.1843 45.669 12.0122 44.8794 12.0122C43.0683 12.0122 41.5221 13.3838 41.2207 15.3079C41.0647 16.2677 41.2863 17.1855 41.8308 17.8255C42.3312 18.414 43.0448 18.6592 43.895 18.6592C45.3542 18.6592 46.1634 17.721 46.1634 17.721L46.0903 18.1763C46.0628 18.3505 46.1969 18.5066 46.3726 18.5066H47.8483C48.082 18.5066 48.2812 18.3364 48.3178 18.1048L49.204 12.4956C49.2309 12.3229 49.0969 12.1663 48.9213 12.1663ZM46.6368 15.356C46.4796 16.2922 45.7355 16.9208 44.7878 16.9208C44.3128 16.9208 43.9315 16.7681 43.6873 16.4789C43.4452 16.1916 43.354 15.7828 43.4302 15.3274C43.5787 14.3991 44.3334 13.7501 45.2667 13.7501C45.7321 13.7501 46.1103 13.9047 46.3596 14.1964C46.6103 14.4912 46.7093 14.9026 46.6368 15.356Z"
      fill="black" />
    <path
      d="M50.8536 9.24108L49.4489 18.1776C49.4214 18.3507 49.5555 18.5068 49.7302 18.5068H51.1423C51.377 18.5068 51.5762 18.3367 51.6123 18.105L52.9974 9.32972C53.0249 9.15651 52.8909 8.99989 52.7162 8.99989H51.1348C50.9952 9.00031 50.8751 9.10253 50.8536 9.24108Z"
      fill="black" />
  </svg>
</template>
