<template>
  <svg width="65" height="90" viewBox="0 0 65 90" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M54.1957 7.72826C48.6196 2.73913 40.7935 0 32.2826 0C23.7717 0 15.9457 2.73913 10.3696 7.72826C3.52174 13.7935 0 22.8913 0 34.2391C0 58.7935 28.2717 87.6522 29.5435 88.8261C30.3261 89.6087 31.3043 90 32.2826 90C33.2609 90 34.337 89.6087 35.0217 88.8261C36.2935 87.6522 64.5652 58.7935 64.5652 34.2391C64.5652 22.8913 61.0435 13.7935 54.1957 7.72826ZM32.2826 80.3152C29.6413 77.3804 25.4348 72.4891 21.3261 66.6196C12.5217 54.0978 7.82609 42.9457 7.82609 34.2391C7.82609 9.78261 26.5109 7.82609 32.2826 7.82609C54.9783 7.82609 56.7391 28.0761 56.7391 34.2391C56.7391 51.4565 39.2283 72.6848 32.2826 80.3152ZM32.2826 16.9239C23.6739 16.9239 16.7283 24.0652 16.7283 32.7717C16.7283 41.4783 23.6739 48.5217 32.2826 48.5217C40.8913 48.5217 47.837 41.3804 47.837 32.6739C47.837 23.9674 40.8913 16.9239 32.2826 16.9239ZM32.2826 41.6739C27.4891 41.6739 23.5761 37.663 23.5761 32.6739C23.5761 27.6848 27.4891 23.6739 32.2826 23.6739C37.0761 23.6739 40.9891 27.6848 40.9891 32.6739C40.9891 37.663 37.0761 41.6739 32.2826 41.6739Z"
      fill="url(#paint0_linear_407_6120)" />
    <defs>
      <linearGradient
        id="paint0_linear_407_6120"
        x1="9.82514"
        y1="13.2065"
        x2="68.0992"
        y2="59.1238"
        gradientUnits="userSpaceOnUse">
        <stop offset="0.245" stop-color="#1EEAAD" />
        <stop offset="1" stop-color="#5733E4" />
      </linearGradient>
    </defs>
  </svg>
</template>
