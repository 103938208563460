<template>
  <svg
    id="svg12"
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg">
    <defs id="defs16" />
    <g id="Group 1048" transform="translate(-0.5,-0.5)">
      <path id="Line 81" d="M 16,4 15,19" stroke="#000000" stroke-linecap="round" />
      <path id="Line 86" d="M 3,4 4,19" stroke="#000000" stroke-linecap="round" />
      <path id="Line 77" d="M 1,4 H 18" stroke="#000000" stroke-linecap="round" />
      <path id="Line 78" d="M 4,19 H 15" stroke="#000000" stroke-linecap="round" />
      <path id="Line 82" d="m 7.5,7 v 9" stroke="#000000" stroke-linecap="round" />
      <path id="Line 84" d="M 6,1 V 4" stroke="#000000" stroke-linecap="round" />
      <path id="Line 85" d="M 13,1 V 4" stroke="#000000" stroke-linecap="round" />
      <path id="Line 83" d="m 11.5,7 v 9" stroke="#000000" stroke-linecap="round" />
      <path id="Line 79" d="m 6,1 h 7" stroke="#000000" stroke-linecap="round" />
    </g>
  </svg>
</template>
