export default defineNuxtRouteMiddleware((to, from) => {
  if (from.path === '/bestellbestaetigung' && to.path !== '/') {
    return navigateTo({ path: '/' })
  }
  if (to.path === '/bestellbestaetigung' && from.path !== '/kasse') {
    return navigateTo({ path: '/' })
  }
  if (to.path !== to.path.toLowerCase()) {
    return navigateTo({ path: to.path.toLowerCase() })
  }
})
