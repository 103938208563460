<template>
  <svg width="62" height="29" viewBox="0 0 62 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="61" height="28" rx="2.5" stroke="#BACBDD" />
    <path d="M34.399 8.60449H27.8359V20.3958H34.399V8.60449Z" fill="#5E5E5E" />
    <path
      d="M28.2718 14.5C28.2718 12.1044 29.3981 9.97816 31.1262 8.60437C29.8544 7.60437 28.25 7 26.5 7C22.3544 7 19 10.3544 19 14.5C19 18.6456 22.3544 22 26.5 22C28.25 22 29.8544 21.3956 31.1262 20.3956C29.3956 19.0413 28.2718 16.8956 28.2718 14.5Z"
      fill="black" />
    <path
      d="M43.2512 14.5C43.2512 18.6456 39.8968 22 35.7512 22C34.0012 22 32.3968 21.3956 31.125 20.3956C32.875 19.0194 33.9794 16.8956 33.9794 14.5C33.9794 12.1044 32.8532 9.97816 31.125 8.60437C32.3944 7.60437 33.9988 7 35.7488 7C39.8968 7 43.2512 10.3762 43.2512 14.5Z"
      fill="#959595" />
  </svg>
</template>
