import { default as _91slug_93S0js3RtOm6Meta } from "/src/pages/[slug].vue?macro=true";
import { default as agb6tGal4VeolMeta } from "/src/pages/agb.vue?macro=true";
import { default as bestellbestaetigung9xWNbRzQxUMeta } from "/src/pages/bestellbestaetigung.vue?macro=true";
import { default as datenschutzerklaerungNuptjT3kklMeta } from "/src/pages/datenschutzerklaerung.vue?macro=true";
import { default as impressum0XojDA8GKUMeta } from "/src/pages/impressum.vue?macro=true";
import { default as indexvsa0KwJWqiMeta } from "/src/pages/index.vue?macro=true";
import { default as kassekIMTWRnSLOMeta } from "/src/pages/kasse.vue?macro=true";
import { default as warenkorbgkP95vmclSMeta } from "/src/pages/warenkorb.vue?macro=true";
export default [
  {
    name: "slug___de",
    path: "/:slug()",
    component: () => import("/src/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___en",
    path: "/en/:slug()",
    component: () => import("/src/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: "agb___de",
    path: "/agb",
    component: () => import("/src/pages/agb.vue").then(m => m.default || m)
  },
  {
    name: "agb___en",
    path: "/en/agb",
    component: () => import("/src/pages/agb.vue").then(m => m.default || m)
  },
  {
    name: "bestellbestaetigung___de",
    path: "/bestellbestaetigung",
    meta: bestellbestaetigung9xWNbRzQxUMeta || {},
    component: () => import("/src/pages/bestellbestaetigung.vue").then(m => m.default || m)
  },
  {
    name: "bestellbestaetigung___en",
    path: "/en/bestellbestaetigung",
    meta: bestellbestaetigung9xWNbRzQxUMeta || {},
    component: () => import("/src/pages/bestellbestaetigung.vue").then(m => m.default || m)
  },
  {
    name: "datenschutzerklaerung___de",
    path: "/datenschutzerklaerung",
    component: () => import("/src/pages/datenschutzerklaerung.vue").then(m => m.default || m)
  },
  {
    name: "datenschutzerklaerung___en",
    path: "/en/datenschutzerklaerung",
    component: () => import("/src/pages/datenschutzerklaerung.vue").then(m => m.default || m)
  },
  {
    name: "impressum___de",
    path: "/impressum",
    component: () => import("/src/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: "impressum___en",
    path: "/en/impressum",
    component: () => import("/src/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/",
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "kasse___de",
    path: "/kasse",
    meta: kassekIMTWRnSLOMeta || {},
    component: () => import("/src/pages/kasse.vue").then(m => m.default || m)
  },
  {
    name: "kasse___en",
    path: "/en/kasse",
    meta: kassekIMTWRnSLOMeta || {},
    component: () => import("/src/pages/kasse.vue").then(m => m.default || m)
  },
  {
    name: "warenkorb___de",
    path: "/warenkorb",
    component: () => import("/src/pages/warenkorb.vue").then(m => m.default || m)
  },
  {
    name: "warenkorb___en",
    path: "/en/warenkorb",
    component: () => import("/src/pages/warenkorb.vue").then(m => m.default || m)
  }
]