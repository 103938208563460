import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin(nuxtApp => {
  const router = useRouter()
  const config = useRuntimeConfig()
  const sentry = config.public.sentry

  if (!sentry.dsn) {
    return
  }

  const tracesSampleRate = config.public.sentry.environment === 'production' ? 1.0 : 0.2
  const replaysOnErrorSampleRate = config.public.sentry.environment === 'production' ? 1.0 : 0.1

  if (sentry.environment !== 'development') {
    // Initialize Sentry only for non dev environment
    console.log('Initializing Sentry', sentry.environment)
    Sentry.init({
      app: nuxtApp.vueApp,
      dsn: sentry.dsn,
      environment: sentry.environment,
      integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],

      tracesSampleRate,

      //control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [config.public.baseUri],

      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate,
    })
  }
})
