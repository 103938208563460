import { defineStore } from 'pinia'
import type { Cart, CustomerDetails, DeliveryAddress, Discount, Product } from '~/types/types'

export const useCartStore = defineStore('cart', () => {
  const MAX_PRODUCT_QUANTITY = 10
  const dateOption: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone: 'Europe/Berlin',
  }

  const cart = ref<Cart>({
    products: [],
    billingTotals: {
      netPrice: 0,
      grossPrice: 0,
      vat: 0,
      volumePrice: 0,
    },
    customerDetails: {
      company: undefined,
      vatId: undefined,
      email: '',
      firstName: '',
      lastName: '',
      deliveryAddress: {
        phoneNumber: '',
        street: '',
        houseNumber: '',
        deliveryNotes: '',
        postalCode: '',
        city: '',
        positionOnPublicGround: false,
      },
      billingAddress: {
        billingStreet: '',
        billingHouseNumber: '',
        billingPostalCode: '',
        billingCity: '',
      },
      paymentMethod: undefined,
    },
    discount: undefined,
    endPriceForPayment: {
      netPrice: 0,
      grossPrice: 0,
      vat: 0,
      volumePrice: 0,
    },
    stripeTransactionId: undefined,
  })
  function getCart() {
    const { $customerStorage } = useNuxtApp()
    const cartInLocalStore = $customerStorage.getCart()
    if (cartInLocalStore) {
      cart.value = cartInLocalStore
    }

    return cart.value
  }
  function saveCartInStorage() {
    const { $customerStorage } = useNuxtApp()
    $customerStorage.setCart(cart.value)
  }
  function removeCartInStorage() {
    const { $customerStorage } = useNuxtApp()
    $customerStorage.removeCart()
  }

  function addProductToCart(product: Product) {
    if (!product) return

    const sameProductInCart = cart.value.products.find(
      item =>
        item.offerId === product.offerId &&
        item.deliveryDate.every(
          (date, index) =>
            date.toLocaleDateString('de-DE', dateOption) ===
            product.deliveryDate[index].toLocaleDateString('de-DE', dateOption),
        ),
    ) // check if offer AND delivery dates are the same

    if (sameProductInCart) {
      increaseQuantity(product)

      return
    }

    cart.value.products.push(product)
    saveCartInStorage()
  }

  function increaseQuantity(product: Product) {
    const sameProductInCart = cart.value.products.find(
      item =>
        item.offerId === product.offerId &&
        item.deliveryDate.every(
          (date, index) =>
            date.toLocaleDateString('de-DE', dateOption) ===
            product.deliveryDate[index].toLocaleDateString('de-DE', dateOption),
        ),
    )

    if (sameProductInCart && sameProductInCart.quantity >= MAX_PRODUCT_QUANTITY) return

    if (sameProductInCart) {
      sameProductInCart.quantity++
    }
    saveCartInStorage()
  }

  function decreaseQuantity(product: Product) {
    const sameProductInCart = cart.value.products.find(
      item =>
        item.offerId === product.offerId &&
        item.deliveryDate.every(
          (date, index) =>
            date.toLocaleDateString('de-DE', dateOption) ===
            product.deliveryDate[index].toLocaleDateString('de-DE', dateOption),
        ),
    )

    if (sameProductInCart && sameProductInCart.quantity > 1) {
      sameProductInCart.quantity--
    }
    saveCartInStorage()
  }

  function removeProductFromCart(product: Product) {
    cart.value.products = cart.value?.products.filter(
      item =>
        item.offerId !== product.offerId ||
        !item.deliveryDate.every(
          (date, index) =>
            date.toLocaleDateString('de-DE', dateOption) ===
            product.deliveryDate[index].toLocaleDateString('de-DE', dateOption),
        ),
    )
    saveCartInStorage()
  }

  function calculateUnitTotalPrices(product: Product) {
    const productInStore = cart.value.products.find(
      item =>
        item.offerId === product.offerId &&
        item.deliveryDate.every(
          (date, index) =>
            date.toLocaleDateString('de-DE', dateOption) ===
            product.deliveryDate[index].toLocaleDateString('de-DE', dateOption),
        ),
    )

    if (!productInStore) return

    productInStore.totalProductPrice = {
      netPrice: productInStore.quantity * productInStore.unitPrice.netPrice,
      grossPrice: productInStore.quantity * productInStore.unitPrice.grossPrice,
      vat: productInStore.quantity * productInStore.unitPrice.vat,
      volumePrice: productInStore.quantity * productInStore.unitPrice.volumePrice,
      extraRentFeeNett: productInStore.quantity * product.extraRentFeeNett,
      extraRentFeeGross: productInStore.quantity * product.extraRentFeeGross,
      extraRentFeeVat:
        productInStore.quantity * (product.extraRentFeeGross - product.extraRentFeeNett),
    }
    saveCartInStorage()
  }

  function calculateTotalPrices() {
    const totalPrices = cart.value.products.reduce(
      (accu, product) => {
        if (product.totalProductPrice) {
          accu.netPrice +=
            product.totalProductPrice.netPrice + (product.totalProductPrice.extraRentFeeNett || 0)
          accu.grossPrice +=
            product.totalProductPrice.grossPrice +
            (product.totalProductPrice.extraRentFeeGross || 0)
          accu.vat +=
            product.totalProductPrice.vat + (product.totalProductPrice.extraRentFeeVat || 0)
          accu.volumePrice += product.totalProductPrice.volumePrice
        }

        return accu
      },
      {
        netPrice: 0,
        grossPrice: 0,
        vat: 0,
        volumePrice: 0,
      },
    )

    cart.value.billingTotals = totalPrices
    cart.value.endPriceForPayment = totalPrices
    if (cart.value.discount) {
      applyDiscountAtTheEnd(cart.value.discount)
    }
    saveCartInStorage()
  }

  function applyDiscountAtTheEnd(discount: Discount) {
    const billingTotals = cart.value.billingTotals
    cart.value.discount = discount
    let discountNett = 0
    let discountVat = 0
    let discountGross = 0
    let totalNett = billingTotals.netPrice
    let totalVat = billingTotals.vat
    let totalGross = billingTotals.grossPrice
    if (discount.percentage && discount.percentage > 0) {
      discountNett = Number(((billingTotals.netPrice / 100) * discount.percentage).toFixed(2))
      discountVat = Number(((billingTotals.vat / 100) * discount.percentage).toFixed(2))
      discountGross = Number(((billingTotals.grossPrice / 100) * discount.percentage).toFixed(2))
      totalNett = totalNett - discountNett
      totalVat = totalVat - discountVat
      totalGross = totalGross - discountGross
    }

    if (discount.value && discount.value > 0) {
      const discountAmount = discount.value * 100
      totalNett = billingTotals.netPrice - discountAmount
      const vatPercentage = (billingTotals.vat / billingTotals.netPrice) * 100
      totalVat = totalVat - Number(((discountAmount * vatPercentage) / 100).toFixed(2))
      totalGross = totalNett + totalVat
    }
    cart.value.endPriceForPayment = {
      netPrice: totalNett,
      grossPrice: totalGross,
      vat: totalVat,
      volumePrice: billingTotals.volumePrice,
    }
    saveCartInStorage()
  }

  function removeDiscount() {
    cart.value.discount = undefined
    cart.value.endPriceForPayment = cart.value.billingTotals
    saveCartInStorage()
  }

  function addCustomerDetailsToCart(customerDetails: CustomerDetails) {
    cart.value.customerDetails = customerDetails
    saveCartInStorage()
  }
  function addDeliveryAddressToCart(deliveryAddress: DeliveryAddress) {
    cart.value.customerDetails.deliveryAddress = deliveryAddress
    saveCartInStorage()
  }
  function updatePaymentMethod(paymentMethod: number) {
    cart.value.customerDetails.paymentMethod = paymentMethod
    saveCartInStorage()
  }
  function storeStripeTransactionId(stripeTransactionId: string) {
    cart.value.stripeTransactionId = stripeTransactionId
    cart.value.orderPaidAndConfirmed = true
    saveCartInStorage()
  }
  function clearCart() {
    cart.value = {
      products: [],
      billingTotals: {
        netPrice: 0,
        grossPrice: 0,
        vat: 0,
        volumePrice: 0,
      },
      customerDetails: {
        company: undefined,
        vatId: undefined,
        email: '',
        firstName: '',
        lastName: '',
        deliveryAddress: {
          phoneNumber: '',
          street: '',
          houseNumber: '',
          deliveryNotes: '',
          postalCode: '',
          city: '',
          positionOnPublicGround: false,
        },
        billingAddress: {
          billingStreet: '',
          billingHouseNumber: '',
          billingPostalCode: '',
          billingCity: '',
        },
        paymentMethod: undefined,
      },
      discount: undefined,
      endPriceForPayment: {
        netPrice: 0,
        grossPrice: 0,
        vat: 0,
        volumePrice: 0,
      },
      stripeTransactionId: undefined,
    }
    removeCartInStorage()
  }
  return {
    cart,
    addProductToCart,
    removeProductFromCart,
    calculateUnitTotalPrices,
    calculateTotalPrices,
    increaseQuantity,
    decreaseQuantity,
    addCustomerDetailsToCart,
    addDeliveryAddressToCart,
    getCart,
    MAX_PRODUCT_QUANTITY,
    applyDiscountAtTheEnd,
    removeDiscount,
    updatePaymentMethod,
    storeStripeTransactionId,
    clearCart,
  }
})
