import type { Product } from '~/types/types'

export default defineNuxtPlugin(() => {
  const cartStore = useCartStore()

  cartStore.$onAction(({ name, after, onError, args }) => {
    const product =
      args &&
      Array.isArray(args) &&
      args[0] &&
      !Object.prototype.hasOwnProperty.call(args[0], 'street')
        ? args[0]
        : undefined

    if (
      name === 'addProductToCart' ||
      name === 'removeProductFromCart' ||
      name === 'increaseQuantity' ||
      name === 'decreaseQuantity'
    ) {
      after(() => {
        if (product) cartStore.calculateUnitTotalPrices(product as Product)

        cartStore.calculateTotalPrices()
      })

      onError(error => {
        console.error(`Failed to update the cart due to: ${error}`)
      })
    }
  })
})
