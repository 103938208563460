export default defineNuxtPlugin(() => {
  return {
    provide: {
      clickskeksConsent: {
        getObject() {
          const consentStorage = import.meta.client
            ? localStorage.getItem('ccm_consent')
            : undefined
          return JSON.parse(consentStorage || '{}')
        },
      },
    },
  }
})
