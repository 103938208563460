import check from '@/assets/icons/custom/check.vue'
import checklist from '@/assets/icons/custom/checklist.vue'
import fancypencil from '@/assets/icons/custom/fancypencil.vue'
import inputcorrect from '@/assets/icons/custom/inputcorrect.vue'
import location from '@/assets/icons/custom/location.vue'
import mastercard from '@/assets/icons/custom/mastercard.vue'
import overhere from '@/assets/icons/custom/overhere.vue'
import paypal from '@/assets/icons/custom/paypal.vue'
import person from '@/assets/icons/custom/person.vue'
import phone from '@/assets/icons/custom/phone.vue'
import polygon from '@/assets/icons/custom/polygon.vue'
import trashcan from '@/assets/icons/custom/trashcan.vue'
import truck from '@/assets/icons/custom/truck.vue'
import visa from '@/assets/icons/custom/visa.vue'
import { h } from 'vue'
import type { IconProps, IconSet } from 'vuetify'
const customSvgNameToComponent: any = {
  polygon,
  checklist,
  check,
  truck,
  trashcan,
  fancypencil,
  visa,
  mastercard,
  paypal,
  person,
  phone,
  location,
  inputcorrect,
  overhere,
}

const customSVGs: IconSet = {
  component: (props: IconProps) => h(customSvgNameToComponent[props.icon]),
}

export { customSVGs /* aliases */ }
