import { customSVGs } from '@/assets/customSvgs'
import { cde2024Light } from '@/assets/themes'
import DateFnsAdapter from '@date-io/date-fns'
import deDE from 'date-fns/locale/de'
import enUS from 'date-fns/locale/en-US'
import { createVuetify } from 'vuetify'
import { mdi } from 'vuetify/iconsets/mdi'

// Components
import {
  VAppBar,
  VAvatar,
  VBtn,
  VCard,
  VContainer,
  VSelect,
  VSheet,
  VTextField,
} from 'vuetify/components'

import { de, en } from 'vuetify/locale'

export default defineNuxtPlugin(nuxtApp => {
  const vuetify = createVuetify({
    locale: {
      messages: { en, de },
      fallback: 'de',
      locale: 'de',
    },
    date: {
      adapter: DateFnsAdapter,
      locale: {
        en: enUS,
        de: deDE,
      },
    },
    ssr: {
      clientWidth: 1280,
    },
    display: {
      mobileBreakpoint: 'sm',
    },
    theme: {
      defaultTheme: 'cde2024Light',
      themes: {
        cde2024Light,
      },
    },
    aliases: {
      ButtonPrimary: VBtn,
      ButtonSecondary: VBtn,
      ButtonTertiary: VBtn,
      ButtonText: VBtn,
      ButtonHeader: VBtn,
      ButtonShadow: VBtn,
      SelectAbfallart: VSelect,
      VAppBarMobile: VAppBar,
      ContactAvatar: VAvatar,
      ContentWrapper: VContainer,
      HeroTextfield: VTextField,
      HeroSelect: VSelect,
      CdeCard: VCard,
      CountDownCard: VCard,
      FaqButton: VBtn,
      SheetForButton: VSheet,
    },
    defaults: {
      VRow: {
        style: 'margin-left: 0px; margin-right: 0px;',
      },
      SheetForButton: {
        elevation: 4,
        rounded: true,
        class: 'sheet-for-button',
        style: 'box-shadow: 0px 0px 10px 0px #4B88FF26 !important; cursor: pointer;',
      },
      VBtn: {
        style: 'text-transform: none;',
      },
      ButtonPrimary: {
        color: 'primary',
        rounded: 'pill',
        size: 'x-large',
        elevation: 0,
        small: {
          size: 'large',
        },
        style: 'text-transform: none; min-width: 240px;',
      },
      ButtonSecondary: {
        variant: 'outlined',
        color: 'black',
        rounded: 'pill',
        size: 'x-large',
        elevation: 0,
        small: {
          size: 'large',
        },
        style: 'text-transform: none; border: 2px solid black;',
      },
      ButtonTertiary: {
        variant: 'outlined',
        color: 'black',
        rounded: 'pill',
        size: 'x-large',
        'append-icon': 'mdi-open-in-new',
        elevation: 0,
        small: {
          size: 'large',
        },
        style: 'text-transform: none; border: 2px solid black;',
      },
      ButtonText: {
        variant: 'text',
        color: 'secondaryText',
      },
      ButtonHeader: {
        rounded: '3',
        density: 'dense',
        size: 'small',
        height: '56px',
        elevation: 0,
        style:
          'background-color: rgb(var(--v-theme-white)); border: 1px solid rgb(var(--v-theme-border));',
      },
      ButtonShadow: {
        rounded: 'pill',
        elevation: 0,
        height: '60px',
        style: 'box-shadow: 0px 0px 20px 0px #4B88FF26   !important;',
      },
      FaqButton: {
        color: 'primary',
        rounded: 'pill',
        elevation: 0,
        size: 'large',
        style: 'max-width: 40px; max-height: 40px',
      },
      VTextField: {
        variant: 'outlined',
        color: 'secondaryText',
        baseColor: 'secondaryText',
        rounded: 'lg',
        bgColor: 'white',
        hideDetails: 'true',
      },
      VTextarea: {
        variant: 'outlined',
        color: 'secondaryText',
        baseColor: 'secondaryText',
        rounded: 'lg',
        bgColor: 'white',
        hideDetails: 'true',
      },
      SelectAbfallart: {},
      VRadio: {
        color: 'primary',
      },
      VCheckbox: {
        color: 'primary',
        inline: true,
      },
      VTooltip: {
        activator: 'parent',
        location: 'top',
        maxWidth: '300',
      },
      CdeCard: {
        elevation: 4,
        rounded: 'lg',
        class: 'cde-card',
      },
      VAppBar: {
        absolute: false,
        elevation: '0',
        density: 'prominent',
      },
      VAppBarMobile: {
        absolute: false,
        elevation: '0',
        density: 'comfortable',
      },
      VBadge: {
        color: 'primary',
      },
      VSelect: {
        bgColor: 'white',
      },
      ContactAvatar: {
        color: 'white',
        variant: 'outlined',
        style:
          'border: 5px solid white; filter: drop-shadow(20px 20px 25px rgba(75, 136, 255, 0.15));',
        opacity: '1',
      },
      ContentWrapper: {
        fluid: true,
        style: 'padding: 0;',
      },
      CountDownCard: {
        color: 'secondary',
        rounded: 'lg',
        style:
          ' background: linear-gradient(163.3deg, #7064F5 19.38%, #534AB6 88.46%); box-shadow: 0px 0px 20px 0px #4B88FF26   !important;',
      },
      HeroTextfield: {
        rounded: 'lg',
        variant: 'plain',
      },
      HeroSelect: {
        rounded: 'lg',
        variant: 'plain',
      },
      VMenu: {
        style: 'z-index: 10002 !important;',
      },
    },
    icons: {
      defaultSet: 'mdi',
      sets: {
        mdi,
        custom: customSVGs,
      },
    },
  })
  nuxtApp.vueApp.use(vuetify)
})
