import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAtomsIconClose, LazyAtomsPaypalMastercardVisa, LazyCardCustomerRating, LazyCardBenefit, LazyCardSolution, LazyCardWaste, LazyCartEmpty, LazyCartSingleProduct, LazyCheckoutCustomerSummary, LazyCheckoutDeliveryAddress, LazyCheckoutPayment, LazyCmsB2bHero, LazyCmsCardsWrapper, LazyCmsContact, LazyCmsContent, LazyCmsCustomTable, LazyCmsCustomerEvaluation, LazyCmsFaq, LazyCmsHero, LazyCmsInfobox, LazyCmsSizeWrapper, LazyCmsTextCta, LazyCmsTextListcard, LazyCmsTextPicture, LazyCmsText, LazyCmsWasteHero, LazyCmsWasteRule, LazyComponentSidebar, LazyDottedArrow, LazyFooterFirstRow, LazyFooterSecondRow, LazyFooterThirdRow, LazyGlobalSidebar, LazyGlobalFooter, LazyGlobalHeaderCheckout, LazyGlobalHeaderDesktop, LazyGlobalHeaderMobile, LazyGlobalHeader, LazyGlobalMainMenu, LazyGlobalScrollTop, LazyGlobalWasteDropdown, LazyH2Number, LazyHeroContainerDetail, LazyHeroCounter, LazyHeroForm, LazyHeroHint, LazyHeroOfferNotFound, LazyHeroPostcode, LazyHeroPriceChangeOverlay, LazyNumberOnCircle, LazyOrderNow, LazyOverlayDifferentZipcode, LazyPageNotFound, LazyPaymentStripe, LazySidebarCartSummary, LazySidebarChangePostcode, LazySidebarChooseContainer, LazySidebarChooseFraction, LazySidebarPlaceRequirement, LazySidebarSelectDate, LazySidebarWasteRule, LazyTrustedShopDataConfirmation } from '#components'
const lazyGlobalComponents = [
  ["AtomsIconClose", LazyAtomsIconClose],
["AtomsPaypalMastercardVisa", LazyAtomsPaypalMastercardVisa],
["CardCustomerRating", LazyCardCustomerRating],
["CardBenefit", LazyCardBenefit],
["CardSolution", LazyCardSolution],
["CardWaste", LazyCardWaste],
["CartEmpty", LazyCartEmpty],
["CartSingleProduct", LazyCartSingleProduct],
["CheckoutCustomerSummary", LazyCheckoutCustomerSummary],
["CheckoutDeliveryAddress", LazyCheckoutDeliveryAddress],
["CheckoutPayment", LazyCheckoutPayment],
["CmsB2bHero", LazyCmsB2bHero],
["CmsCardsWrapper", LazyCmsCardsWrapper],
["CmsContact", LazyCmsContact],
["CmsContent", LazyCmsContent],
["CmsCustomTable", LazyCmsCustomTable],
["CmsCustomerEvaluation", LazyCmsCustomerEvaluation],
["CmsFaq", LazyCmsFaq],
["CmsHero", LazyCmsHero],
["CmsInfobox", LazyCmsInfobox],
["CmsSizeWrapper", LazyCmsSizeWrapper],
["CmsTextCta", LazyCmsTextCta],
["CmsTextListcard", LazyCmsTextListcard],
["CmsTextPicture", LazyCmsTextPicture],
["CmsText", LazyCmsText],
["CmsWasteHero", LazyCmsWasteHero],
["CmsWasteRule", LazyCmsWasteRule],
["ComponentSidebar", LazyComponentSidebar],
["DottedArrow", LazyDottedArrow],
["FooterFirstRow", LazyFooterFirstRow],
["FooterSecondRow", LazyFooterSecondRow],
["FooterThirdRow", LazyFooterThirdRow],
["GlobalSidebar", LazyGlobalSidebar],
["GlobalFooter", LazyGlobalFooter],
["GlobalHeaderCheckout", LazyGlobalHeaderCheckout],
["GlobalHeaderDesktop", LazyGlobalHeaderDesktop],
["GlobalHeaderMobile", LazyGlobalHeaderMobile],
["GlobalHeader", LazyGlobalHeader],
["GlobalMainMenu", LazyGlobalMainMenu],
["GlobalScrollTop", LazyGlobalScrollTop],
["GlobalWasteDropdown", LazyGlobalWasteDropdown],
["H2Number", LazyH2Number],
["HeroContainerDetail", LazyHeroContainerDetail],
["HeroCounter", LazyHeroCounter],
["HeroForm", LazyHeroForm],
["HeroHint", LazyHeroHint],
["HeroOfferNotFound", LazyHeroOfferNotFound],
["HeroPostcode", LazyHeroPostcode],
["HeroPriceChangeOverlay", LazyHeroPriceChangeOverlay],
["NumberOnCircle", LazyNumberOnCircle],
["OrderNow", LazyOrderNow],
["OverlayDifferentZipcode", LazyOverlayDifferentZipcode],
["PageNotFound", LazyPageNotFound],
["PaymentStripe", LazyPaymentStripe],
["SidebarCartSummary", LazySidebarCartSummary],
["SidebarChangePostcode", LazySidebarChangePostcode],
["SidebarChooseContainer", LazySidebarChooseContainer],
["SidebarChooseFraction", LazySidebarChooseFraction],
["SidebarPlaceRequirement", LazySidebarPlaceRequirement],
["SidebarSelectDate", LazySidebarSelectDate],
["SidebarWasteRule", LazySidebarWasteRule],
["TrustedShopDataConfirmation", LazyTrustedShopDataConfirmation],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
