<template>
  <svg width="90" height="85" viewBox="0 0 90 85" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M45 52.9239C59.4783 52.9239 71.3152 41.087 71.3152 26.5109C71.3152 11.837 59.4783 0 45 0C30.5217 0 18.6848 11.837 18.6848 26.413C18.6848 41.087 30.5217 52.9239 45 52.9239ZM45 7.82609C55.1739 7.82609 63.4891 16.1413 63.4891 26.413C63.4891 36.6848 55.1739 45 45 45C34.8261 45 26.5109 36.6848 26.5109 26.413C26.5109 16.1413 34.8261 7.82609 45 7.82609ZM57.2283 55.4674C55.9565 55.0761 54.4891 55.3696 53.5109 56.25L45 63.4891L36.4891 56.25C35.413 55.3696 34.0435 55.0761 32.7717 55.4674C27.2935 57.2283 0 66.6196 0 80.2174C0 82.3696 1.76087 84.1304 3.91304 84.1304H86.087C88.2391 84.1304 90 82.3696 90 80.2174C90 66.6196 62.7065 57.2283 57.2283 55.4674ZM9.88043 76.3043C14.1848 71.7065 24.5543 66.6196 33.163 63.6848L42.4565 71.6087C43.9239 72.8804 46.0761 72.8804 47.5435 71.6087L56.837 63.6848C65.4456 66.7174 75.8152 71.8043 80.1196 76.3043H9.88043Z"
      fill="url(#paint0_linear_407_6110)" />
    <defs>
      <linearGradient
        id="paint0_linear_407_6110"
        x1="13.6956"
        y1="12.3452"
        x2="69.0028"
        y2="77.3306"
        gradientUnits="userSpaceOnUse">
        <stop offset="0.245" stop-color="#1EEAAD" />
        <stop offset="1" stop-color="#5733E4" />
      </linearGradient>
    </defs>
  </svg>
</template>
