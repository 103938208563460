import type { Cart, Product } from '~/types/types'

export default defineNuxtPlugin(() => {
  return {
    provide: {
      customerStorage: {
        setCart(value: Cart) {
          if (import.meta.client) {
            return localStorage.setItem('cdeCart', JSON.stringify(value))
          }
        },
        getCart() {
          if (import.meta.client) {
            const cartString = localStorage.getItem('cdeCart')
            const rawCart: Cart = cartString ? JSON.parse(cartString) : undefined
            const products: Product[] = []

            if (!rawCart) {
              return undefined
            }
            for (const rawProduct of rawCart.products) {
              const deliveryDate = rawProduct.deliveryDate.map(date => new Date(date))
              const product: Product = {
                quantity: rawProduct.quantity,
                deliveryDate: deliveryDate,
                offerId: rawProduct.offerId,
                containerPlacementTime: rawProduct.containerPlacementTime,
                totalProductPrice: rawProduct.totalProductPrice
                  ? {
                      netPrice: rawProduct.totalProductPrice.netPrice,
                      grossPrice: rawProduct.totalProductPrice.grossPrice,
                      vat: rawProduct.totalProductPrice.vat,
                      volumePrice: rawProduct.totalProductPrice.volumePrice,
                      extraRentFeeGross: rawProduct.totalProductPrice.extraRentFeeGross,
                      extraRentFeeNett: rawProduct.totalProductPrice.extraRentFeeNett,
                      extraRentFeeVat: rawProduct.totalProductPrice.extraRentFeeVat,
                    }
                  : undefined,
                directusContainerId: rawProduct.directusContainerId,
                emptoContainerId: rawProduct.emptoContainerId,
                directusFractionId: rawProduct.directusFractionId,
                emptoFractionId: rawProduct.emptoFractionId,
                vatPercentage: rawProduct.vatPercentage,
                unitPrice: {
                  netPrice: rawProduct.unitPrice.netPrice,
                  grossPrice: rawProduct.unitPrice.grossPrice,
                  volumePrice: rawProduct.unitPrice.volumePrice,
                  vat: rawProduct.unitPrice.vat,
                },
                productHeight: rawProduct.productHeight,
                productLength: rawProduct.productLength,
                productSize: rawProduct.productSize,
                productSpace: rawProduct.productSpace,
                productWidth: rawProduct.productWidth,
                description: rawProduct.description,
                size: rawProduct.size,
                space: rawProduct.space,
                dimension: rawProduct.dimension,
                image: rawProduct.image,
                extraRentFeeNett: rawProduct.extraRentFeeNett,
                extraRentFeeGross: rawProduct.extraRentFeeGross,
              }
              products.push(product)
            }
            const cart: Cart = {
              products: products,
              billingTotals: {
                netPrice: rawCart.billingTotals.netPrice,
                grossPrice: rawCart.billingTotals.grossPrice,
                vat: rawCart.billingTotals.vat,
                volumePrice: rawCart.billingTotals.volumePrice,
              },
              customerDetails: {
                company: rawCart.customerDetails.company,
                vatId: rawCart.customerDetails.vatId,
                email: rawCart.customerDetails.email,
                firstName: rawCart.customerDetails.firstName,
                lastName: rawCart.customerDetails.lastName,
                deliveryAddress: {
                  phoneNumber: rawCart.customerDetails.deliveryAddress.phoneNumber,
                  street: rawCart.customerDetails.deliveryAddress.street,
                  houseNumber: rawCart.customerDetails.deliveryAddress.houseNumber,
                  postalCode: rawCart.customerDetails.deliveryAddress.postalCode,
                  city: rawCart.customerDetails.deliveryAddress.city,
                  deliveryNotes: rawCart.customerDetails.deliveryAddress.deliveryNotes,
                  positionOnPublicGround:
                    rawCart.customerDetails.deliveryAddress.positionOnPublicGround,
                },
                billingAddress: {
                  invoiceReceiver: rawCart.customerDetails.billingAddress.invoiceReceiver,
                  receiverAttachment: rawCart.customerDetails.billingAddress.receiverAttachment,
                  billingStreet: rawCart.customerDetails.billingAddress.billingStreet,
                  billingHouseNumber: rawCart.customerDetails.billingAddress.billingHouseNumber,
                  billingPostalCode: rawCart.customerDetails.billingAddress.billingPostalCode,
                  billingCity: rawCart.customerDetails.billingAddress.billingCity,
                },
                paymentMethod: rawCart.customerDetails.paymentMethod,
              },
              endPriceForPayment: {
                netPrice: rawCart.endPriceForPayment?.netPrice || rawCart.billingTotals.netPrice,
                grossPrice:
                  rawCart.endPriceForPayment?.grossPrice || rawCart.billingTotals.grossPrice,
                vat: rawCart.endPriceForPayment?.vat || rawCart.billingTotals.vat,
                volumePrice:
                  rawCart.endPriceForPayment?.volumePrice || rawCart.billingTotals.volumePrice,
              },
              discount: rawCart.discount,
            }
            return cart
          } else {
            return undefined
          }
        },
        removeCart() {
          if (import.meta.client) {
            return localStorage.removeItem('cdeCart')
          }
        },
      },
    },
  }
})
