import unhead_KgADcZ0jPj from "/src/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/src/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/src/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/src/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/src/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/src/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/src/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/src/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/src/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/src/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import titles_dw2T9lEw4h from "/src/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import defaultsWaitI18n_fcc0iSNKKy from "/src/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaultsWaitI18n.js";
import nuxt_plugin_6wEQMY3tee from "/src/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import switch_locale_path_ssr_5csfIgkrBP from "/src/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/src/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_ghbUAjaD3n from "/src/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import cart_store_actions_V7UPAUTdbO from "/src/plugins/cart-store-actions.ts";
import clickskeks_consent_4Gn27cPjD6 from "/src/plugins/clickskeks-consent.ts";
import customer_EzwsfA6VNk from "/src/plugins/customer.ts";
import directus_fdYmlw0WUJ from "/src/plugins/directus.ts";
import preview_6oGpsaen2C from "/src/plugins/preview.ts";
import sentry_client_shVUlIjFLk from "/src/plugins/sentry.client.ts";
import trustedshops_client_NVWzei4RiE from "/src/plugins/trustedshops.client.ts";
import vue_gtm_client_stBjLl0OeM from "/src/plugins/vue-gtm.client.ts";
import vuetify_7h9QAQEssH from "/src/plugins/vuetify.ts";
export default [
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  titles_dw2T9lEw4h,
  defaultsWaitI18n_fcc0iSNKKy,
  nuxt_plugin_6wEQMY3tee,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  plugin_ghbUAjaD3n,
  cart_store_actions_V7UPAUTdbO,
  clickskeks_consent_4Gn27cPjD6,
  customer_EzwsfA6VNk,
  directus_fdYmlw0WUJ,
  preview_6oGpsaen2C,
  sentry_client_shVUlIjFLk,
  trustedshops_client_NVWzei4RiE,
  vue_gtm_client_stBjLl0OeM,
  vuetify_7h9QAQEssH
]