export default defineNuxtPlugin(() => {
  const loadTrustedShopsScript = (): void => {
    const config = useRuntimeConfig()
    const tsid = config.public.tsid
    const script = document.createElement('script')
    script.async = true
    script.charset = 'UTF-8'
    script.src = `//widgets.trustedshops.com/js/${tsid}.js`

    // Add the data attributes
    script.dataset.desktopYOffset = '0'
    script.dataset.mobileYOffset = '0'
    script.dataset.desktopDisableReviews = 'false'
    script.dataset.desktopEnableCustom = 'true'
    script.dataset.desktopPosition = 'right'
    script.dataset.desktopCustomWidth = '130'
    script.dataset.desktopEnableFadeout = 'false'
    script.dataset.disableMobile = 'false'
    script.dataset.disableTrustbadge = 'false'
    script.dataset.mobileCustomWidth = '156'
    script.dataset.mobileDisableReviews = 'true'
    script.dataset.mobileEnableCustom = 'false'
    script.dataset.mobilePosition = 'right'
    script.dataset.mobileEnableTopbar = 'false'
    script.dataset.mobileEnableFadeout = 'true'
    script.dataset.colorScheme = 'light'

    // Append the script to the document body
    document.body.appendChild(script)
  }

  return {
    provide: {
      loadTrustedShopsScript,
    },
  }
})
