<template>
  <svg width="62" height="29" viewBox="0 0 62 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="62" height="29" fill="#F5F5F5" />
    <g id="3.2_Kasse Desktop Rabattcodeeingabe" clip-path="url(#clip0_5_219)">
      <rect width="1920" height="1769" transform="translate(-949 -769)" fill="#F6FAFE" />
      <rect id="Rectangle 218" x="-709" y="-283" width="832" height="792" fill="white" />
      <g id="Group 993">
        <rect id="Rectangle 14" x="0.5" y="0.5" width="61" height="28" rx="2.5" stroke="#BACBDD" />
        <g id="Payment-Icons">
          <g id="Kreditkarte">
            <g id="Visa">
              <path
                id="Combined-Shape"
                d="M21.1256 16.4771C22.0155 14.0504 22.9167 11.626 23.8156 9.20164C24.794 9.21299 25.7701 9.20845 26.7462 9.20164C25.2616 12.7497 23.8769 16.3386 22.3605 19.873C21.4071 19.8435 20.4537 19.8571 19.5003 19.8571C18.6558 16.7722 17.8681 13.6713 17.0305 10.5841C16.0884 10.0189 15.0374 9.6897 14 9.34693C14.4585 9.11084 14.9829 9.20618 15.4755 9.1971C16.6037 9.22661 17.7342 9.16986 18.8624 9.2198C19.3709 9.21753 19.8067 9.63976 19.8771 10.1324C20.2312 11.8485 20.5558 13.5714 20.9032 15.2921C20.9894 15.6826 21.0507 16.0821 21.1256 16.4771Z"
                fill="black" />
              <path
                id="Path"
                d="M27.8428 9.19678C28.7622 9.20359 29.6815 9.20359 30.6009 9.19678C30.0357 12.7516 29.4409 16.302 28.8893 19.8591C27.9745 19.8614 27.0597 19.8614 26.1426 19.8591C26.6692 16.2997 27.2889 12.7539 27.8428 9.19678Z"
                fill="black" />
              <path
                id="Path_2"
                d="M33.2159 9.86662C34.864 8.78382 37.0182 8.8156 38.8411 9.41488C38.7571 10.2026 38.6254 10.9835 38.4892 11.7621C37.5199 11.2967 36.3894 11.0902 35.3362 11.3603C34.839 11.4783 34.3487 12.0958 34.7618 12.5589C35.6108 13.4283 36.9479 13.6258 37.7469 14.5724C38.6731 15.4781 38.639 17.0036 38.008 18.0637C37.3406 19.1828 36.0558 19.7685 34.805 19.9365C33.3907 20.084 31.9175 19.9955 30.5918 19.4484C30.7325 18.6539 30.8506 17.8548 30.9936 17.0603C32.14 17.6528 33.4702 17.9502 34.7482 17.6664C35.1818 17.5211 35.629 17.1648 35.5904 16.6563C35.4474 16.0502 34.814 15.7914 34.3215 15.5167C33.3658 15.0468 32.3374 14.4702 31.9084 13.4374C31.375 12.1321 32.0809 10.6044 33.2159 9.86662Z"
                fill="black" />
              <path
                id="Combined-Shape_2"
                d="M45.9152 9.2108C46.6712 12.7611 47.4021 16.3137 48.1467 19.864C47.2977 19.8663 46.4487 19.8663 45.5997 19.8686C45.4794 19.3351 45.3659 18.7994 45.2569 18.2614C44.0788 18.2614 42.9029 18.2637 41.727 18.2569C41.5386 18.7948 41.3434 19.3328 41.1414 19.8663C40.1789 19.8663 39.2164 19.8663 38.2539 19.8686C39.5841 16.6746 40.928 13.4853 42.256 10.2891C42.4398 9.79873 42.803 9.26528 43.3774 9.23577C44.2218 9.17674 45.0708 9.22669 45.9152 9.2108ZM44.0357 12.0937C43.4613 13.3876 43.0414 14.7451 42.5284 16.064C43.2911 16.0663 44.0516 16.0663 44.8143 16.0663C44.5396 14.7451 44.2059 13.433 44.0357 12.0937Z"
                fill="black" />
            </g>
          </g>
        </g>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_5_219">
        <rect width="1920" height="1769" fill="white" transform="translate(-949 -769)" />
      </clipPath>
    </defs>
  </svg>
</template>
