import type { ThemeDefinition } from 'vuetify'

export const name = 'cde2024Light'
export const cde2024Light: ThemeDefinition = {
  dark: false,
  colors: {
    primary: '#1EEAAD', //light green
    'primary-lighten': '#85FFDA', //lighter green
    secondary: '#5733E4', //purple
    surface: '#FFFFFF',
    'surface-darken': '#F6FAFE', // light blue for cart table bg
    'surface-darken2': '#f6f6f6', // light grey for faq bg
    'surface-gradient': '#F1F8FF', // light blue for cart table bg
    white: '#FFFFFF',
    black: '#000000',
    warning: '#FF7373', //light red
    error: '#FF7373', //light red
    success: '#1EEAAD', //light green same as primary
    text: '#444444', //main text color
    'text-lighten': '#999999', //main text color but lighter
    'shadow-tooltip': '#4B88FF26', //tooltip shadow color
    border: '#BACBDD', //light blue grey
    secondaryText: '#8198BD', // mid blue used via class name secondary-text
    'on-primary': '#004438', // dark green on primary background (like buttons)
    'on-secondary': '#FFFFFF', //automatic white text on purple background
  },
}
